import React from 'react'
import { Text } from '../ui'
import { EventDuration } from '../events'
import { PostList, PostListSkeleton } from '../posts'

const mapPosts = (posts) => posts.map((post) => ({
  ...post,
  type: post.type.toLowerCase(),
  meta: {
    Post: post.meta,
    Event: <EventDuration {...post} />,
    Location: [post.town, post.address].filter((v) => !!v).join(', '),
  }[post.type],
}))

// TODO error

const SearchResults = ({ loading, error, searchResults }) => {
  if (loading) {
    return <PostListSkeleton />
  }

  if (error || !searchResults.length) {
    return <Text>Nincs találat</Text>
  }

  return <PostList posts={mapPosts(searchResults)} />
}

export default SearchResults
