import React from 'react'
import { Flex, Box } from '../ui'
import PostPreviewSkeleton from './PostPreviewSkeleton'

const PostListSkeleton = () => (
  <Flex
    flexWrap="wrap"
    mx={[0, -2]}
  >
    <Box
      pb={3}
      px={[0, 2]}
      width={[1, 1 / 2]}
    >
      <PostPreviewSkeleton />
    </Box>
    <Box
      pb={3}
      px={[0, 2]}
      width={[1, 1 / 2]}
    >
      <PostPreviewSkeleton />
    </Box>
    <Box
      pb={3}
      px={[0, 2]}
      width={[1, 1 / 2]}
    >
      <PostPreviewSkeleton />
    </Box>
    <Box
      pb={3}
      px={[0, 2]}
      width={[1, 1 / 2]}
    >
      <PostPreviewSkeleton />
    </Box>
    <Box
      pb={3}
      px={[0, 2]}
      width={[1, 1 / 2]}
    >
      <PostPreviewSkeleton />
    </Box>
  </Flex>
)

export default PostListSkeleton
