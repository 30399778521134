import React from 'react'
import { TextBlock, TextRow, RectShape } from 'react-placeholder/lib/placeholders'
import { Box } from '../ui'

const PostPreviewSkeleton = () => (
  <Box color="secondary.50">
    <RectShape
      color="currentColor"
      style={{ width: '100%', height: 170 }}
    />

    <TextRow
      color="currentColor"
      style={{ marginTop: 8 }}
    />
    <TextRow
      color="currentColor"
      style={{ marginTop: 8, width: '80%' }}
    />

    <TextBlock
      color="currentColor"
      rows={3}
      style={{ marginTop: 16 }}
    />
  </Box>
)

export default PostPreviewSkeleton
